import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/HelpSupport.css';

const HelpSupport = () => {
  const faqs = [
    {
      question: "How do I get started with FinArchitect?",
      answer: "Getting started is easy! Simply click the 'Request Demo' button in the navigation bar, and our team will guide you through the process. We'll help you set up your account and provide a personalized onboarding experience."
    },
    {
      question: "What type of support do you offer?",
      answer: "We offer comprehensive support including email support, live chat during business hours, video tutorials, documentation, and scheduled training sessions for enterprise customers."
    },
    {
      question: "How secure is my financial data?",
      answer: "We take security seriously. All data is encrypted both in transit and at rest, and we use industry-standard security protocols. Our servers are hosted in secure facilities with regular security audits."
    },
    {
      question: "Can I export my financial models?",
      answer: "Yes! You can export your financial models in various formats including Excel, PDF, and CSV. Enterprise users also have access to API integration for automated exports."
    },
    {
      question: "Do you offer custom solutions?",
      answer: "Yes, we offer customized solutions for enterprise clients. Our team can work with you to develop specific features and integrations that meet your unique needs."
    }
  ];

  const supportChannels = [
    {
      title: "Email Support",
      description: "Send us an email anytime",
      contact: "support@finarchitect.site",
      responseTime: "Response within 24 hours",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="support-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
      )
    },
    {
      title: "Live Chat",
      description: "Chat with our support team",
      hours: "Monday-Friday, 9AM-5PM EST",
      availability: "Instant response during business hours",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="support-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
        </svg>
      )
    },
    {
      title: "Phone Support",
      description: "For enterprise customers",
      contact: "+234 (808) 462-9437",
      hours: "24/7 support",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="support-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
        </svg>
      )
    }
  ];

  return (
    <div className="page-container">
      <Header />
      <div className="help-support">
        <div className="help-hero">
          <div className="hero-content">
            <h1>Help & Support</h1>
            <p>We're here to help you succeed with FinArchitect</p>
          </div>
        </div>

        <div className="content-container">
          <div className="support-channels">
            <h2>Contact Us</h2>
            <div className="channels-grid">
              {supportChannels.map((channel, index) => (
                <div key={index} className="channel-card">
                  <div className="channel-icon">{channel.icon}</div>
                  <h3>{channel.title}</h3>
                  <p className="description">{channel.description}</p>
                  {channel.contact && <p className="contact">{channel.contact}</p>}
                  <p className="hours">{channel.hours || channel.responseTime}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="documentation-section">
            <h2>Documentation & Resources</h2>
            <div className="resources-grid">
              <a href="#guides" className="resource-card">
                <svg xmlns="http://www.w3.org/2000/svg" className="resource-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                </svg>
                <h3>User Guides</h3>
                <p>Step-by-step guides for using FinArchitect</p>
              </a>
              <a href="#tutorials" className="resource-card">
                <svg xmlns="http://www.w3.org/2000/svg" className="resource-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                <h3>Video Tutorials</h3>
                <p>Watch tutorials on key features</p>
              </a>
              <a href="#api" className="resource-card">
                <svg xmlns="http://www.w3.org/2000/svg" className="resource-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                </svg>
                <h3>API Documentation</h3>
                <p>Technical documentation for developers</p>
              </a>
            </div>
          </div>

          <div className="faq-section">
            <h2>Frequently Asked Questions</h2>
            <div className="faq-grid">
              {faqs.map((faq, index) => (
                <div key={index} className="faq-card">
                  <h3>{faq.question}</h3>
                  <p>{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HelpSupport;
