import React, { useState } from 'react';
import './Features.css';

const Features = () => {
  const [activeCategory, setActiveCategory] = useState(0);

  const features = [
    {
      category: "Financial Modeling",
      description: "Build sophisticated financial models with ease",
      icon: "💹",
      items: [
        {
          title: "Smart Templates",
          description: "Pre-built financial models and templates for different industries and use cases. Save weeks of work with our battle-tested frameworks.",
          icon: "📊",
          highlight: "Save 80% time",
          stats: "500+ templates"
        },
        {
          title: "Real-time Calculations",
          description: "Automatic calculations and updates as you input data. No more manual formula checking or broken spreadsheets.",
          icon: "⚡",
          highlight: "Zero errors",
          stats: "100% accuracy"
        },
        {
          title: "Scenario Analysis",
          description: "Create and compare multiple scenarios to understand different business outcomes and make informed decisions.",
          icon: "🎯",
          highlight: "Quick insights",
          stats: "∞ scenarios"
        }
      ]
    },
    {
      category: "Collaboration & Sharing",
      description: "Work seamlessly with your team",
      icon: "🤝",
      items: [
        {
          title: "Team Workspace",
          description: "Work together in real-time with your team. Share models, leave comments, and track changes efficiently.",
          icon: "👥",
          highlight: "Real-time sync",
          stats: "Unlimited users"
        },
        {
          title: "Version Control",
          description: "Never lose work with automatic versioning. Track changes and restore previous versions when needed.",
          icon: "🔄",
          highlight: "Auto-save",
          stats: "30-day history"
        },
        {
          title: "Presentation Ready",
          description: "Export beautiful reports and presentations with one click. Impress stakeholders with professional outputs.",
          icon: "📈",
          highlight: "One-click export",
          stats: "10+ formats"
        }
      ]
    },
    {
      category: "Data & Integration",
      description: "Connect and analyze your data",
      icon: "🔌",
      items: [
        {
          title: "Data Import",
          description: "Import data from Excel, CSV, or connect directly to your accounting software. Automated data validation included.",
          icon: "📥",
          highlight: "Auto-validation",
          stats: "99.9% accuracy"
        },
        {
          title: "API Access",
          description: "Connect to external data sources and automate your workflow with our robust API integration.",
          icon: "🔌",
          highlight: "Full API access",
          stats: "50+ integrations"
        },
        {
          title: "Custom Functions",
          description: "Create your own functions and formulas to match your specific business needs and calculations.",
          icon: "⚙️",
          highlight: "Customizable",
          stats: "200+ functions"
        }
      ]
    }
  ];

  return (
    <section className="features">
      <div className="features-header">
        <span className="badge">Features</span>
        <h2>Powerful Features for Modern Finance Teams</h2>
        <p>Everything you need to build professional financial models and make data-driven decisions</p>
      </div>

      <div className="features-nav">
        {features.map((category, index) => (
          <button
            key={index}
            className={`nav-item ${activeCategory === index ? 'active' : ''}`}
            onClick={() => setActiveCategory(index)}
          >
            <span className="nav-icon">{category.icon}</span>
            {category.category}
          </button>
        ))}
      </div>

      <div className="features-container">
        {features.map((category, index) => (
          <div
            key={index}
            className={`feature-category ${activeCategory === index ? 'active' : ''}`}
          >
            <div className="category-header">
              <h3 className="category-title">{category.category}</h3>
              <p className="category-description">{category.description}</p>
            </div>
            <div className="feature-grid">
              {category.items.map((feature, featureIndex) => (
                <div key={featureIndex} className="feature-card">
                  <div className="feature-card-inner">
                    <div className="feature-icon">{feature.icon}</div>
                    <div className="feature-content">
                      <div className="feature-header">
                        <h4>{feature.title}</h4>
                        <span className="feature-highlight">{feature.highlight}</span>
                      </div>
                      <p>{feature.description}</p>
                      <div className="feature-stats">
                        <span className="stats-icon">📈</span>
                        {feature.stats}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="features-hero">
        <div className="limited-offer">Limited Time Offer</div>
        <div className="features-hero-content">
          <h2>Ready to transform your financial modeling?</h2>
          <p>Join thousands of finance professionals using FinArchitect</p>
          <div className="features-stats">
            <div className="stat-item">
              <span className="stat-number">50x</span>
              <span className="stat-label">Faster than manual</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">4.9/5</span>
              <span className="stat-label">User Rating</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">99.9%</span>
              <span className="stat-label">Uptime</span>
            </div>
          </div>
          <div className="features-cta">
            <a href="/trial" className="cta-button">Start Free Trial →</a>
            <a href="/demo" className="cta-button secondary">Schedule Demo 📅</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;