import React from 'react';
import './Mission.css';

const Mission = () => {
  const testimonials = [
    {
      id: 1,
      name: "Sarah Chen",
      role: "CFO, TechGrowth Inc",
      image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?w=150&h=150&fit=crop&crop=faces&auto=format",
      quote: "FinArchitect has transformed how we handle financial modeling. What used to take weeks now takes days.",
    },
    {
      id: 2,
      name: "Marcus Rodriguez",
      role: "Financial Director, Scale Ventures",
      image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=150&h=150&fit=crop&crop=faces&auto=format",
      quote: "The most intuitive financial modeling software I've used. It's become an essential part of our planning process.",
    },
    {
      id: 3,
      name: "Emily Watson",
      role: "CEO, Future Finance",
      image: "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=150&h=150&fit=crop&crop=faces&auto=format",
      quote: "FinArchitect helped us make data-driven decisions with confidence. The ROI has been incredible.",
    }
  ];

  const stats = [
    {
      number: "50x",
      label: "Faster than manual"
    },
    {
      number: "$2B+",
      label: "Modeled Revenue"
    },
    {
      number: "98%",
      label: "Customer Satisfaction"
    },
    {
      number: "4.9/5",
      label: "Average Rating"
    }
  ];

  const companyLogos = [
    {
      name: "Microsoft",
      url: "https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg"
    },
    {
      name: "Deloitte",
      url: "https://upload.wikimedia.org/wikipedia/commons/5/56/Deloitte.svg"
    },
    {
      name: "Goldman Sachs",
      url: "https://upload.wikimedia.org/wikipedia/commons/6/61/Goldman_Sachs.svg"
    },
    {
      name: "Morgan Stanley",
      url: "https://upload.wikimedia.org/wikipedia/commons/3/34/Morgan_Stanley_Logo_1.svg"
    },
    {
      name: "JP Morgan",
      url: "https://upload.wikimedia.org/wikipedia/commons/a/af/J_P_Morgan_Logo_2008_1.svg"
    }
  ];

  return (
    <section className="mission">
      <div className="mission-header">
        <h2>Trusted by Finance Leaders Worldwide</h2>
        <p>Join thousands of companies making better financial decisions with FinArchitect</p>
      </div>

      <div className="stats-grid">
        {stats.map((stat, index) => (
          <div key={index} className="stat-card">
            <h3>{stat.number}</h3>
            <p>{stat.label}</p>
          </div>
        ))}
      </div>

      <div className="testimonials-section">
        <div className="testimonials-grid">
          {testimonials.map(testimonial => (
            <div key={testimonial.id} className="testimonial-card">
              <div className="testimonial-header">
                <img 
                  src={testimonial.image} 
                  alt={testimonial.name} 
                  className="testimonial-image"
                  loading="lazy"
                />
                <div className="testimonial-author">
                  <h4>{testimonial.name}</h4>
                  <p>{testimonial.role}</p>
                </div>
              </div>
              <blockquote>
                <p>"{testimonial.quote}"</p>
              </blockquote>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Mission;