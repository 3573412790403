import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/FinancialDictionary.css';

export const financialTerms = [
  {
    term: "DCF (Discounted Cash Flow)",
    definition: "A valuation method that estimates the value of an investment based on its expected future cash flows, adjusted for the time value of money."
  },
  {
    term: "EBITDA",
    definition: "Earnings Before Interest, Taxes, Depreciation, and Amortization. A measure of a company's overall financial performance."
  },
  {
    term: "NPV (Net Present Value)",
    definition: "The difference between the present value of cash inflows and outflows over a period of time, used to analyze the profitability of an investment or project."
  },
  {
    term: "WACC (Weighted Average Cost of Capital)",
    definition: "The average rate a company is expected to pay to finance its assets, weighted by the proportion of debt and equity in its capital structure."
  },
  {
    term: "ROI (Return on Investment)",
    definition: "A performance measure used to evaluate the efficiency or profitability of an investment, calculated by dividing the benefit by the cost."
  },
  {
    term: "Working Capital",
    definition: "The difference between a company's current assets and current liabilities, representing its ability to fund ongoing operations."
  },
  {
    term: "Cash Flow Statement",
    definition: "A financial statement that provides aggregate data regarding all cash inflows and outflows a company receives from its ongoing operations and external investment sources."
  },
  {
    term: "Balance Sheet",
    definition: "A financial statement that reports a company's assets, liabilities, and shareholders' equity at a specific point in time."
  },
  {
    term: "Income Statement",
    definition: "A financial statement that measures a company's financial performance over a specific accounting period, showing profit or loss."
  },
  {
    term: "Depreciation",
    definition: "The allocation of the cost of an asset over its useful life, representing how much of an asset's value has been used up."
  },
  {
    term: "Capital Expenditure (CapEx)",
    definition: "Funds used by a company to acquire, upgrade, and maintain physical assets such as property, buildings, or equipment."
  },
  {
    term: "Operating Expense (OpEx)",
    definition: "The ongoing costs a company incurs in its normal business operations."
  },
  {
    term: "Gross Margin",
    definition: "The difference between revenue and cost of goods sold, divided by revenue, expressed as a percentage."
  },
  {
    term: "Burn Rate",
    definition: "The rate at which a company is losing money, typically expressed as negative cash flow per month."
  },
  {
    term: "P/E Ratio (Price-to-Earnings)",
    definition: "A ratio for valuing a company by measuring its current share price relative to its earnings per share (EPS)."
  }
];

const FinancialDictionary = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredTerms = financialTerms.filter(item =>
    item.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.definition.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getTermSlug = (term) => {
    return term.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-');
  };

  return (
    <div className="page-container">
      <Header />
      <div className="financial-dictionary">
        <div className="dictionary-hero">
          <div className="hero-content">
            <h1>Financial Dictionary</h1>
            <p>Master the language of financial modeling with our comprehensive glossary</p>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search financial terms..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
              <div className="search-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        
        <div className="terms-container">
          {filteredTerms.map((item, index) => (
            <Link 
              to={`/dictionary/${getTermSlug(item.term)}`} 
              className="term-card" 
              key={index}
            >
              <h3>{item.term}</h3>
              <p>{item.definition.length > 150 ? `${item.definition.substring(0, 150)}...` : item.definition}</p>
              <span className="read-more">Read more →</span>
            </Link>
          ))}
          {filteredTerms.length === 0 && (
            <div className="no-results">
              <p>No terms found matching your search.</p>
              <button 
                className="reset-search" 
                onClick={() => setSearchTerm('')}
              >
                Clear Search
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FinancialDictionary;
