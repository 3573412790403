import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/TermPage.css';
import { financialTerms } from './FinancialDictionary';

const TermPage = () => {
  const { termSlug } = useParams();
  const term = financialTerms.find(t => 
    t.term.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-') === termSlug
  );

  if (!term) {
    return (
      <div className="page-container">
        <Header />
        <div className="term-not-found">
          <h1>Term Not Found</h1>
          <p>The financial term you're looking for could not be found.</p>
          <Link to="/financial-dictionary" className="back-to-dictionary">
            ← Back to Dictionary
          </Link>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="page-container">
      <Helmet>
        <title>{`${term.term} - Financial Term Definition | FinArchitect`}</title>
        <meta name="description" content={term.definition.slice(0, 155) + '...'} />
        <meta property="og:title" content={`${term.term} - Financial Term Definition`} />
        <meta property="og:description" content={term.definition.slice(0, 155) + '...'} />
        <meta name="keywords" content={`${term.term}, financial term, financial definition, finance, financial modeling`} />
        <link rel="canonical" href={`https://finarchitect.site/dictionary/${termSlug}`} />
      </Helmet>

      <Header />
      
      <div className="term-page">
        <div className="term-header">
          <div className="term-header-content">
            <h1>{term.term}</h1>
            <Link to="/financial-dictionary" className="back-to-dictionary">
              ← Back to Dictionary
            </Link>
          </div>
        </div>

        <div className="term-content">
          <div className="term-definition">
            <h2>Definition</h2>
            <p>{term.definition}</p>
          </div>
          
          <div className="term-details">
            <div className="usage-examples">
              <h2>Usage in Financial Modeling</h2>
              <p>Understanding {term.term} is crucial for financial modeling and analysis. This concept is widely used in:</p>
              <ul>
                <li>Valuation Analysis</li>
                <li>Financial Planning</li>
                <li>Investment Decision Making</li>
                <li>Risk Assessment</li>
              </ul>
            </div>
            
            <div className="related-resources">
              <h2>Related Terms</h2>
              <ul>
                {financialTerms
                  .filter(t => t.term !== term.term)
                  .slice(0, 5)
                  .map((relatedTerm, index) => (
                    <li key={index}>
                      <Link 
                        to={`/dictionary/${relatedTerm.term.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-')}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {relatedTerm.term}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermPage;
