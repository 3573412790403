import React from 'react';
import Header from './Header';
import Hero from './Hero';
import Mission from './Mission';
import Features from './Features';
import Footer from './Footer';
import '../App.css'

function Homepage() {
  return (
    <div className="app">
      <Header />
      <Hero />
      <Mission />
      <Features />
      <Footer />
    </div>
  );
}

export default Homepage;