import React, { useState } from 'react';
import './Hero.css';
import heroAnimation from '../heroAnimation2.json';
import Lottie from 'lottie-react';

const Hero = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage({ text: '', type: '' });
    
    try {
      const response = await fetch('https://finarchitect.online/communications/emails/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      
      if (response.ok) {
        setMessage({ 
          text: 'Success! Your free guide is on its way to your inbox.', 
          type: 'success' 
        });
        setEmail('');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit email');
      }
    } catch (error) {
      console.error('Error submitting email:', error);
      setMessage({ 
        text: 'Something went wrong. Please try again.', 
        type: 'error' 
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="hero">
      <div className="hero-pattern"></div>
      <div className="hero-content">
        <div className="hero-badge">
          <span>🚀</span> Trusted by companies worldwide
        </div>
        <div className="hero-text">
          <h1>Transform Your<br /><span className="gradient-text">Financial Planning</span></h1>
          <p>Build professional financial models in minutes, not hours. FinArchitect helps you make data-driven decisions with confidence.</p>
        </div>
        
        <div className="hero-actions">
          <form onSubmit={handleSubmit} className="hero-form">
            <div className="form-label">
              <span>✨</span> Get our free financial modelling guide
            </div>
            <div className="input-group">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your work email"
                required
                disabled={isLoading}
              />
              <button 
                type="submit" 
                className="primary-btn"
                disabled={isLoading}
              >
                {isLoading ? 'Sending...' : 'Get Free Guide'}
              </button>
            </div>
            {message.text && (
              <div className={`form-message ${message.type}`}>
                {message.text}
              </div>
            )}
          </form>
          
        </div>
        
      </div>
      <div className="hero-image">
         <Lottie animationData={heroAnimation} />
      </div>
    </section>
  );
};

export default Hero;