import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Implement form submission
    console.log('Form submitted:', formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="contact-page">
      <Header />
      
      <main className="contact-content">
        <section className="contact-hero">
          <div className="container">
            <h1>Get in Touch</h1>
            <p className="lead">We'd love to hear from you. Let us know how we can help.</p>
          </div>
        </section>

        <section className="contact-options">
          <div className="container">
            <div className="contact-grid">
              <div className="contact-form-container">
                <h2>Send us a Message</h2>
                <form className="contact-form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Full Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="company">Company Name</label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      rows="4"
                    ></textarea>
                  </div>
                  <button type="submit" className="submit-btn">Send Message</button>
                </form>
              </div>

              <div className="contact-info">
                <div className="info-card">
                  <h3>Support</h3>
                  <p>Need help with FinArchitect?</p>
                  <a href="mailto:support@finarchitect.com">support@finarchitect.com</a>
                </div>
                <div className="info-card">
                  <h3>Sales</h3>
                  <p>Interested in our enterprise solutions?</p>
                  <a href="mailto:sales@finarchitect.com">sales@finarchitect.com</a>
                </div>
                <div className="info-card">
                  <h3>Office</h3>
                  <p>123 Financial District<br />Silicon Valley, CA 94025</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Contact;
