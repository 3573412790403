import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/Pricing.css';

const Pricing = () => {
  const [isAnnual, setIsAnnual] = useState(false);

  const plans = [
    {
      name: 'Basic',
      monthlyPrice: 49.99,
      annualPrice: 479.99, // Save ~$120/year
      features: [
        '1 Financial Model',
        'Export to Excel/PDF',
        'Basic Support',
        'Regular Updates'
      ]
    },
    {
      name: 'Professional',
      monthlyPrice: 129.99,
      annualPrice: 1249.99, // Save ~$310/year
      features: [
        'Up to 3 Financial Models',
        'Priority Support',
        'Advanced Analytics',
        'Custom Templates',
        'API Access'
      ]
    },
    {
      name: 'Enterprise',
      price: 'Custom',
      features: [
        'Unlimited Models',
        'Dedicated Account Manager',
        'Custom Integrations',
        '24/7 Premium Support',
        'Custom Contracts'
      ]
    }
  ];

  return (
    <div className="page-container">
      <Header />
      
      <main className="pricing-content">
        <section className="pricing-hero">
          <div className="container">
            <h1>Simple, Transparent Pricing</h1>
            <p className="lead">Choose the plan that fits your modeling needs</p>
          </div>
        </section>

        <section className="pricing-plans">
          <div className="container">
            <div className="pricing-toggle">
              <span className={!isAnnual ? 'active' : ''}>Monthly</span>
              <label className="switch">
                <input 
                  type="checkbox" 
                  checked={isAnnual}
                  onChange={() => setIsAnnual(!isAnnual)}
                />
                <span className="slider"></span>
              </label>
              <span className={isAnnual ? 'active' : ''}>
                Annual <span className="save-badge">Save up to 20%</span>
              </span>
            </div>
            
            <div className="plans-grid">
              {plans.map((plan) => (
                <div key={plan.name} className={`plan-card ${plan.name === 'Professional' ? 'popular' : ''}`}>
                  {plan.name === 'Professional' && <div className="popular-tag">Most Popular</div>}
                  <div className="plan-header">
                    <div className="plan-name">
                      <h2>{plan.name}</h2>
                      <span className="subtitle">{plan.name === 'Basic' ? 'Single Model' : plan.name === 'Professional' ? 'Multi Model' : 'Custom Solution'}</span>
                    </div>
                    <div className="price">
                      {plan.price ? (
                        <span>{plan.price}</span>
                      ) : (
                        <>
                          <span className="currency">$</span>
                          <span className="amount">{isAnnual ? plan.annualPrice : plan.monthlyPrice}</span>
                          
                          <span className="period">/{isAnnual ? 'year' : 'month'}</span>
                        </>
                      )}
                    </div>
                    <p>{plan.name === 'Basic' ? 'Perfect for individual projects' : plan.name === 'Professional' ? 'Perfect for multiple projects' : 'Tailored for large organizations'}</p>
                  </div>
                  <ul className="plan-features">
                    {plan.features.map((feature, index) => (
                      <li key={index}>
                        <svg className="feature-icon" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <button className="plan-cta">{plan.name === 'Enterprise' ? 'Contact Sales' : plan.name === 'Professional' ? 'Start Free Trial' : 'Get Started'}</button>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="pricing-faq">
          <div className="container">
            <h2>Frequently Asked Questions</h2>
            <div className="faq-grid">
              <div className="faq-item">
                <h3>Can I switch plans later?</h3>
                <p>Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle.</p>
              </div>
              <div className="faq-item">
                <h3>Is there a free trial?</h3>
                <p>Yes, we offer a 14-day free trial on our Professional plan with no credit card required.</p>
              </div>
              <div className="faq-item">
                <h3>What payment methods do you accept?</h3>
                <p>We accept all major credit cards, PayPal, and bank transfers for enterprise customers.</p>
              </div>
              <div className="faq-item">
                <h3>Do you offer refunds?</h3>
                <p>Yes, we offer a 30-day money-back guarantee if you're not satisfied with our service.</p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Pricing;
