import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/About.css';

const About = () => {
  return (
    <div className="about-page">
      <Header />
      
      <main className="about-content">
        <section className="about-hero">
          <div className="container">
            <h1>About FinArchitect</h1>
            <p className="lead">Empowering businesses with intelligent financial modeling</p>
          </div>
        </section>

        <section className="about-story">
          <div className="container">
            <h2>Our Story</h2>
            <p>FinArchitect was born from a simple observation: financial modeling shouldn't be complicated. Our team of financial experts and software engineers came together to create a solution that makes financial planning accessible to businesses of all sizes.</p>
          </div>
        </section>

        <section className="about-values">
          <div className="container">
            <h2>Our Values</h2>
            <div className="values-grid">
              <div className="value-card">
                <div className="value-icon">💡</div>
                <h3>Innovation</h3>
                <p>Constantly pushing boundaries to simplify complex financial processes</p>
              </div>
              <div className="value-card">
                <div className="value-icon">🎯</div>
                <h3>Accuracy</h3>
                <p>Delivering precise, reliable financial models you can trust</p>
              </div>
              <div className="value-card">
                <div className="value-icon">🤝</div>
                <h3>Accessibility</h3>
                <p>Making professional financial tools available to everyone</p>
              </div>
            </div>
          </div>
        </section>

        <section className="about-team">
          <div className="container">
            <h2>Our Team</h2>
            <p>We're a diverse team of financial experts, engineers, and designers working together to revolutionize financial modeling.</p>
            <div className="team-stats">
              <div className="stat">
                <h3>10+</h3>
                <p>Years Combined Experience</p>
              </div>
              <div className="stat">
                <h3>1000+</h3>
                <p>Financial Models Created</p>
              </div>
              <div className="stat">
                <h3>24/7</h3>
                <p>Customer Support</p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default About;
