import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`header ${isScrolled ? 'header-scrolled' : ''}`}>
      <div className="logo">
        <Link to="/">
          <img src='/finarchitechlogo.png' alt='FinArchitect' width={200} />
        </Link>
      </div>
      <nav className={`nav ${isMenuOpen ? 'nav-open' : ''}`}>
        <ul>
          <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
          <li><Link to="/pricing" onClick={toggleMenu}>Pricing</Link></li>
         <li><Link to="/financial-dictionary" onClick={toggleMenu}>Financial Dictionary</Link></li>
         <li><Link to="/help-support" onClick={toggleMenu}>Help & Support</Link></li>
          {/* <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li> */}
          <li className="mobile-only">
            <a 
              href="https://forms.gle/pj7RignyMavHX5k87"
              className="signup"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleMenu}
            >
              Get Started
            </a>
          </li>
        </ul>
      </nav>
      <div className="buttons desktop-only">
        <a 
          href="https://forms.gle/pj7RignyMavHX5k87"
          className="signup"
          target="_blank"
          rel="noopener noreferrer"
        >
          Request Demo
        </a>
      </div>
      <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle Menu">
        {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
      </button>
    </header>
  );
};

export default Header;